<template>
	<div class="main-contents hr">
		
		<div class="tit">담당자 변경<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
		<img @click="clickChangeCorpMgrMberNm()" src="/images/admin/manager-change_btn.png" alt="선택한담당자로변경하기"/></div>
		<div class="search-box interval">
			<SelectComp type="select" list="1:담당자명,2:부서명,3:직급" v-model="input.searchGubun"/>
			<nbsp/>
			<InputComp type="text" :placeholder="input.searchGubun == '1' ? '담당자명' : input.searchGubun == '2' ? '부서명' : '직급'" 
						v-model="input.searchKeyword" @keyup.enter="getProjectMgrList('1')"  />
			<nbsp/>
			<img @click="getProjectMgrList('1')" class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼"/>
		</div>

		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="4%">
					<col width="7%">
					<col width="7%">
					<col width="18%">
					<col width="18%">
					<col width="18%">
					<col width="7%">
					<col width="8%">
					<col width="9%">
				</colgroup>
				<thead>
					<tr>
						<th>선택</th>
						<th>NO</th>
						<th>담당자명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>수요기업명</th>
						<th>부서명</th>
						<th>직급</th>
						<th>회원가입일</th>
						<th>프로젝트담당 승인</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(projectMgr, index) in projectMgrList" :key="projectMgr.mgrMberSeq">
						<td class="radio">
							<input type="radio" v-model="input.mgrMberSeq" :id="index" :value="projectMgr.mgrMberSeq" @change="changeRadio(index)">
							<label :for="index">
								<span></span>
							</label>
						</td>
						<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
						<td class="score name">
							<span v-if="projectMgr.mgrMberNm" @click="popupCorpMgrMber(projectMgr.mgrMberSeq)">{{projectMgr.mgrMberNm}} &#9654;</span>
							<span v-else>-</span>
						</td>
						<td class="score">{{projectMgr.mgrMberSeq}}</td>
						<td class="score">{{projectMgr.loginId}}</td>
						<td class="score">{{input.corpNm}}</td>
						<td class="score">{{projectMgr.deptNm}}</td>
						<td class="score">{{projectMgr.positionNm}}</td>
						<td class="score">{{projectMgr.regYyyymmdd}}</td>
						<td class="score apvl">승인</td>
					</tr>
					<!-- 관련내용 없을 경우 -->
					<tr v-if="pageInfo.totalRecordCount == 0">
						<td colspan="10" class="none">담당자가 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	components: { pagingComp },
	data() {
		return {
			input : {
				projectSeq: '',
				projectNm : '',
				corpSeq : '',
				corpNm : '',
				currCorpMgrMberSeq : '',
				currCorpMgrMberNm : '',
				currCorpMgrLoginId : '',

				mgrMberSeq : '',		// 변경할 담당자
				mgrMberNm : '',
				mbrMberLoginId : '',

				searchGubun : '1',	// 1:담당자명, 2:부서명, 3:직급
				searchKeyword : '',

				parentProjectYn : ''
			},

			projectMgrList : [],			
			pageInfo : {},
		};
	},
	mounted(){

		this.nowYyyymmdd = new Date().format("yyyyMMdd");

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getProjectMgrList();
	},
	methods: {
 		getProjectMgrList(div) {

			if(div) this.input.pageIndex = 1;

			this.$.httpPost('/api/prj/adm/getProjectMgrList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectMgrList = res.data.list;
					this.pageInfo = res.data.pageInfo;
					
					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		}, 
		
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		changeRadio(index) {
			this.input.mgrMberSeq = this.projectMgrList[index].mgrMberSeq;
			this.input.mgrMberNm = this.projectMgrList[index].mgrMberNm;
			this.input.mgrMberLoginId = this.projectMgrList[index].loginId;
		},

		clickChangeCorpMgrMberNm() {

			if(this.input.mgrMberSeq == undefined || this.input.mgrMberSeq ==''){
				alert("변경할 담당자를 선택해주세요!")
				return;
			}

			var input = {
				projectSeq	: this.input.projectSeq,
				projectNm 	: this.input.projectNm,
			
				currCorpMgrMberNm : this.input.currCorpMgrMberNm,
				currCorpMgrLoginId : this.input.currCorpMgrLoginId,

				mgrMberSeq : this.input.mgrMberSeq,		// 선택한 담당자
				mgrMberNm : this.input.mgrMberNm,
				mgrMberLoginId : this.input.mgrMberLoginId,

				parentProjectYn : this.input.parentProjectYn
			}

			this.$.popup('/adm/prj/PRJ910P02', input)
                .then(res => {
                    if(res) {
                    	this.$router.go(-1);
                    }
                });
		},

		// 수요기업의 프로젝트 담당자 정보 보기
		popupCorpMgrMber(corpMgrMberSeq) {
			var param = {};
			param.reqMberSeq = corpMgrMberSeq;
			param.div = 'pmg'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},		
		
	},
};
</script>
